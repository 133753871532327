import { getEnv } from '@/envs'

export const ASSET_PREFIX = 'dlc-fe'
export const IMAGE_PREFIX = 'images'

export const ALGOLIA_INDEX_PREFIX = getEnv('NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX')

export const DOWNLOAD_CENTER_SERVICE_URL = getEnv('NEXT_PUBLIC_DOWNLOAD_CENTER_SERVICE_URL')

export const ANALYTICS_STRING_SEPARATOR = ';'

export const DOWNLOAD_BUTTON_DATACY = 'download-button'
export const CART_DOWNLOAD_BUTTON_DATACY = 'cart-download-button'
export const SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY =
  'DownloadDocument-TableItem-DownloadButton'

export const STICKY_DOWNLOAD_BUTTON_PAPER_HEIGHT = 73

export const BREADCRUMBS_LINKS = [{ href: '#', label: 'Download Center' }]

export const BREADCRUMBS_LINKS_UK = [{ href: '#', label: 'Download Centre' }]
export const LANGUAGE_SELECTOR_HREF = '/en/country-language-selector'

export const DOWNLOAD_CENTER_PATH = '/tools/download-center'
