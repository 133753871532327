import type { ComponentType } from 'react'
import dynamic from 'next/dynamic'

const fontMapping: Record<string, () => Promise<{ default: ComponentType<{}> }>> = {
  Arabic: () => import('./ArabicFont'),
  Hebrew: () => import('./HebrewFont'),
  Japanese: () => import('./JapaneseFont'),
  Korean: () => import('./KoreanFont'),
  Thai: () => import('./ThaiFont'),
  defaultNotoSans: () => import('./defaultAlternative'),
  knauf: () => import('./index'),
}

export const getFontVariant = (fontToUse: string): ComponentType => {
  const loadFontComponent = fontMapping[fontToUse] || (() => import('./defaultAlternative'))

  return dynamic(loadFontComponent)
}
