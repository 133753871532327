import type { Locale } from 'next/router'

import { getDirectionAttribute } from '@knauf-group/ct-designs/utils/utils'

import { parseAdditionalRTLLocales } from './parseAdditionalRTLLocales'
import { getEnv } from '@/envs'

export const isRTLLocale = (locale: Locale): boolean => {
  const localeLowerCase = locale.toLowerCase()
  if (getDirectionAttribute(localeLowerCase) === 'rtl') {
    return true
  }

  return parseAdditionalRTLLocales(getEnv('NEXT_PUBLIC_ADDITIONAL_RTL_LOCALES'))
    .map((rtlLocale) => rtlLocale.trim().toLowerCase())
    .includes(localeLowerCase)
}
