import type { UserConfig } from 'next-i18next'
import {
  CROWDIN_LOCALE_MAPPING,
  DEFAULT_CROWDIN_LOCALE,
  SUPPORTED_LOCALES_SET,
} from '@knauf-group/ct-shared-nextjs/utils/constants'
import I18NextHttpBackend from 'i18next-http-backend'

const isClient = typeof window !== 'undefined'

const CROWDIN_DISTRIBUTION_HASH = '7f009ee929f0583e9db70b8xey1'

const TRANSLATIONS_CACHE_CLEAR_INTERVAL_IN_SECONDS = parseInt(
  process.env.TRANSLATIONS_CACHE_CLEAR_INTERVAL || '86400', // 24h by default
  10,
)

const ns = ['download-center', 'cms', 'dam-assets', 'global-search', 'shared-nextjs'] as const

const i18nextConfig: UserConfig = {
  i18n: {
    defaultLocale: 'default',
    locales: Array.from(SUPPORTED_LOCALES_SET),
  },
  fallbackLng: {
    default: [DEFAULT_CROWDIN_LOCALE],
    'fr-FR': ['fr-BE', 'fr-MA'],
  },
  ns: ns,
  defaultNS: 'download-center',
  use: isClient ? [] : [I18NextHttpBackend],
  backend: {
    loadPath: (
      languages: (keyof typeof CROWDIN_LOCALE_MAPPING)[],
      namespaces: (typeof ns)[number][],
    ) => {
      const lng = languages[0]
      const ns = namespaces[0]
      // some locales utilize the translation of other locales
      const localeToUse = CROWDIN_LOCALE_MAPPING[lng]

      // used when fetching the latest version of the translations from Crowdin CDN
      const currentTimestamp = Date.now()

      return `https://distributions.crowdin.net/${CROWDIN_DISTRIBUTION_HASH}/content/main/${localeToUse}/${ns}.json?timestamp=${currentTimestamp}`
    },
    reloadInterval: TRANSLATIONS_CACHE_CLEAR_INTERVAL_IN_SECONDS * 1000,
  },
  serializeConfig: false,
}

export default i18nextConfig
