import { datadogRum } from '@datadog/browser-rum'
import { initializeDatadogBrowserLogs } from '@knauf-group/ct-shared-nextjs/datadog'
import pkg from '../../../package.json'
import { getEnv } from '@/envs'

const service = 'ct-download-center-frontend'

if (getEnv('NEXT_PUBLIC_DATADOG_IS_RUM_ENABLED')) {
  datadogRum.init({
    applicationId: getEnv('NEXT_PUBLIC_DATADOG_APPLICATION_ID'),
    // any client token is okay
    clientToken: getEnv('NEXT_PUBLIC_DATADOG_LOGS_CLIENT_TOKEN'),
    site: 'datadoghq.eu',
    service,
    env: getEnv('NEXT_PUBLIC_APP_ENV'),
    version: pkg.version,
    sessionSampleRate: getEnv('NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE'),
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

initializeDatadogBrowserLogs({
  service,
  version: pkg.version,
  beforeSend: (log) => {
    // Filter out Qualtrics chunk loading errors
    const isQualitricsError =
      log?.error?.message?.includes('Loading chunk') &&
      log?.error?.message?.includes('qualtrics')

    if (isQualitricsError) {
      return false
    }
    return true
  },
})
